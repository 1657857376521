import React from 'react';
import { Star } from 'lucide-react';

const PopularityBadge = ({ count }) => {
    const safeCount = Number(count) || 0;

    if (safeCount >= 10) {
        return (
            <div className="flex items-center gap-1 bg-yellow-500 text-white px-2 py-1 rounded-full text-sm shadow-sm">
                <Star
                    size={14}
                    className="text-red-500"
                    fill="red"  // 10회 이상은 빨간색으로 채우기
                />
                <span className="font-medium">인기 단어장</span>
            </div>
        );
    }

    if (safeCount >= 1) {
        return (
            <div className="flex items-center gap-1 bg-blue-500 text-white px-2 py-1 rounded-full text-sm shadow-sm">
                <Star
                    size={14}
                    className="text-yellow-400"
                    fill="yellow"  // 1회 이상은 노란색으로 채우기
                />
                <span className="font-medium">추천 단어장</span>
            </div>
        );
    }

    return null;
};

export default PopularityBadge;
export { PopularityBadge };